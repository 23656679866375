<template>
  <div class="h-100 mt-5 flex-display flex-column flex-center">
    <h1 class="form-heading mt-5">Enter your mobile
      number</h1>
    <p class="app-error mt-2">
      {{ error }}
    </p>
    <div class="w-343 mt-3" id="form">
      <div class="form-group" v-if="!code_sent">
        <label class="form-label">Phone</label>
        <div class="d-flex">

          <input id="phone" v-model="phone" @keypress="isNumber($event)"
                 type="tel" class="input-text"
                 onDrop="return false" onPaste="return false"
                 placeholder="123456789"/>
          <input id="country_code" v-model="country_code" type="hidden"/>

        </div>
      </div>
      <div class="form-group" v-if="code_sent">
        <label class="form-label">Code</label>
        <input class="input-text" v-model="confirmation_code"
               placeholder="Enter Code">
      </div>
      <div class="mt-2 text-center mb-2" v-if="code_sent">
        <a class="pointer" @click="sendCode('phone')">Re-send Code</a>
        <p class="text-center text-dark m-1">OR</p>
        <a class="pointer" @click="sendCode('email')">Send By Email</a>
      </div>
      <div v-if="!code_sent">
        <button class="btn mb-5" :disabled="disable" @click="sendCode('phone')">Next
        </button>
      </div>
      <div v-else>
        <button class="btn mb-5" :disabled="( this.confirmation_code === '' )"
                @click="updateProfile">Confirm Number
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import numberHelper from "@/helpers/numberHelper";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

export default {
  name: "EditPhoneNumber",
  data() {
    return {
      phone: "",
      country_code: "44",
      region: "",
      send_by: "mobile",
      email: "",
      user: {},
      disable: true,
      error: "",
      confirmation_code: "",
      code_sent: false,
      action: ""
    }
  },
  mounted() {
    const newInput = document.querySelector("#phone");
    let iti = intlTelInput(newInput, {
      initialCountry: this.region ? this.region : "gb",
      separateDialCode: true,
    });
    newInput.addEventListener("countrychange", () => {
      let getCode = iti.getSelectedCountryData();
      this.country_code = getCode.dialCode;
      this.region = getCode.iso2;
    });

  },
  created() {
    if (JSON.parse(localStorage.getItem("user")) !== null) {
      this.user = JSON.parse(localStorage.getItem("user"));

      if (this.user.phone !== "") {
        this.action = "edit";
        this.country_code = this.user.country_code;
        this.phone = "";
      } else {
        this.action = "add";
        document.getElementById("app-drawer").style.display = "none";
        const removeLeftSidebar = document.getElementsByClassName('v-content');
        if (removeLeftSidebar !== undefined) {
          removeLeftSidebar[0].removeAttribute("style");
        }
      }
    }
  },
  methods: {
    isNumber: numberHelper.isNumber,
    removeLeadingZero(phone) {
      this.phone = numberHelper.removeCharactorAtFirstPlace(phone, "0");
    },
    sendCode(send_by) {
      let loader = this.$loading.show()
      this.$http.put(this.url + "confirmation-code", {
        user_id: this.user.id,
        phone: this.phone,
        country_code: this.country_code,
        email: this.user.email,
        timezone: this.timezone,
        send_to: send_by,
        device_type: "web",
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
        this.code_sent = true;
        this.modalTitle = "Confirmation Code"
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message);
      });
    },

    updateProfile() {
      let loader = this.$loading.show()
      this.$http.put(this.url + "update-phone-number", {
        user_id: this.user.id,
        phone: this.phone,
        email: this.user.email,
        country_code: this.country_code,
        code: this.confirmation_code,
        device_type: "web",
        type: "phone",
        timezone: this.timezone,

      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message, "");
        localStorage.setItem("token",response.data.access_token);
        localStorage.setItem("password_expire_date",response.data.password_expire_date);
        localStorage.setItem("sb_access_token",response.data.sb_access_token);
        localStorage.setItem("user",JSON.stringify(response.data.user));
        this.setHeaderToken(response.data.access_token);
        if (response.data.user.user_type === "APP_ADMIN") {
          this.$router.push("dashboard");
        } else {
          this.$router.push("clients");
        }

      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message);
      });
    },

    checkValidPhone() {
      if (isNaN(this.phone)) {
        this.phone = "";
        return false;
      }else {
        return this.phone === "" || this.user.phone === this.phone;
      }
    },
  },
  watch: {
    phone() {
      this.disable = this.checkValidPhone();
      this.removeLeadingZero(this.phone);
    }
  }
}
</script>
